import { Alert, Card, Checkbox } from "antd";
import step1 from './images/hedwig-step-1.png'
import step2 from './images/hedwig-step-2.png'
import step3 from './images/hedwig-step-3.png'

export function FAQ() {
  return (
    <> 
    <Card title="What is Hedwig?">
      Hedwig is a dynamic routing table. It's a tool that helps developper work. Indeed, it allows you to route traffic from a remote kubernetes clusters directly to your local dev environment.
    </Card>
    <br />
    <br />

    <Card title="Start with Why?">
      Hedwig is a dynamic routing table. It's a tool that helps developper work. Indeed, it allows you to route traffic from a remote kubernetes clusters directly to your local dev environment.<br />
 
    Working with micro services brings questions about the way developers will work day to day. It changes the way we develop. You can not clone a single git repo anymore, and just run `npm run dev` The way you write code, test, deploy will change.<br />

      - How do I know if what I have changed in service A is working well with other services?<br />
      - Do I need to run an entire cluster of services on my local machines each time I want to test something?<br />
      - If I need to test a call between A & B how do I setup A's database and B's database?<br />
    Most of these question are related to how can I test and verify that my changes works well.<br />
    <br />
    <h2>💻 All in local</h2>

    Install a kubernetes cluster in local (like minikube) and run all the services locally.<br />
    <br />
    - ✅ Dev env iso to prod env<br />
    - ❌ It is time-consuming<br />
    - ❌ Requires a lot of resources on the machine. It does not scale as the microservices number grows. Docker doesn't play well with MacOS and hyperkit will drain your CPU.<br />
    - ❌ If databases can run locally, you still can not run SQS, lambda, S3 or other managed resources.<br />
    <br />
    <h2>☁️ All in the Cloud</h2>

    A dev-remote kubernetes cluster can be deployed by any developer. It looks a lot like the squad environment, you share and you deploy when you need.<br />
    <br />
    - ✅ Dev env iso to prod env<br />
    - ❌ The env is shared so developers can not work at the same time on this env. You need to slack the team to use your env. It's a source of problems.<br />
    - ❌ Everytime you want to test you need to deploy, and deployment are slow. Fast iterations, is crucials for a good developer experience.<br />
    <br />
    </Card>
    <br />
    <br />
    <Card title="How does it work?">
      Hedwig is a deployed service in the dev kubernetes environment. Every call to a service points to this service.
      Every calls reads a routing table, to determine where to go next.

      <img alt="example" src={step1} width="100%"/>
      <p>Let's take an example. You want to work on the feature that ships an item to a user. You need to modify the Address Service allow multiple delivery addresses and make a default one.</p>
      <img alt="example" src={step2} width="100%" />
      <p>Let's introduce Hedwig. In the remote cluster, all the services point to it. By default, Hedwig redirects to the service in the env. It acts as a proxy.</p>
      <img alt="example" src={step3} width="100%" />
      <p>When you add an override rule. Hedwig will detect the change and start redirecting the traffic directly to your local service.</p>


    </Card>
    <br />
    <br />
    <Card title="How do I create a rule?">
      Click the button "Add a Rule"
    </Card>
    <br />
    <br />
    <Card title="How do I add a new service?">
      A new servie is the only thing that can not be setup dynamically.<br/>
      <Checkbox>Clone the repo "kube-banking"</Checkbox><br/>
      <Checkbox>Modify "hedwig/overlay/dev/ingress/server"</Checkbox><br/>
      <Checkbox>Under hosts add - `my-new-service.hedwig-api.dev.spendesk-bank.com`</Checkbox><br/>
      <Checkbox>Deploy Hedwig</Checkbox><br/>
      <Checkbox>In your new service config file make sure all the service points to hedwig </Checkbox><br/>
    </Card>
    <br />
    <br />
    <Card title="Why is it call Hedwig?">
      Hedwig is Harry potter Owl. She is responsible to deliver messages to the right recipient. It seemed appropriate.
    </Card>
    <br />
    <br />
    <Card title="Why is Hedwig angry?">
      She is tired to answer all your stupid questions.
    </Card>
    <br />
    <br />
    </>
  )
}
