import { Table, Tag, Space, Button, Alert, Input } from 'antd';
import { useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { AddOrEditRuleModal } from './AddRuleModal';
import axios from 'axios';

const RULES_API = `${process.env.REACT_APP_HEDWIG_API_PROTOCOL}://${process.env.REACT_APP_HEDWIG_API_DOMAIN_NAME}:${process.env.REACT_APP_HEDWIG_API_PORT}/rest/rules`;

export function RulesList() {
  
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentRule, setCurrentRule] = useState(null);
  const [items, setItems] = useState([]);
  const [filteredRules, setFilteredRules] = useState(null);

  const handleAddButton = () => {
    setCurrentRule(null);
    setVisibleModal(true);
  };
  const handleEditButton = (record) => {
    setCurrentRule(record);
    setVisibleModal(true);
  };
  const filterRules = (e) => {
    const search = e.target.value;
    if (!search) {
      setFilteredRules(null);
    } else {
      const filterTable = items.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(search.toLowerCase())
        )
      );
      setFilteredRules(filterTable);
    }
  }

  const addOrEditRule = async (values, id) => {
    setVisibleModal(false);
    setError(null);
    if (id) {
      editRule(values, id);
    } else {
      addRule(values)
    }
    setCurrentRule(null);
  }
  const editRule = async (values, id) => {
    try {
      const response = await axios.post(RULES_API, {
        id: id,
        serviceName: values.serviceName,
        type: values.type,
        value: values.value,
        hostname: values.hostname,
        port: values.port,
        protocol: values.protocol,
        isDefault: values.isDefault,
      });
      setItems([...response.data.rules, ...items.filter(rule => rule.id !== id)]);

    } catch (error) {
      setError(error);
    }
  }
  const deleteRule = async (ruleId) => {
    try {
      const response = await axios.delete(RULES_API, {
        data: {
          ruleId
        }
      });
      setItems(items.filter(rule => rule.id !== ruleId));
    } catch (error) {
      setError(error);
    }
  }
  const addRule = async (values) => {
    try {
      const response = await axios.put(RULES_API, {
        serviceName: values.serviceName,
        type: values.type,
        value: values.value,
        hostname: values.hostname,
        port: values.port,
        protocol: values.protocol,
        isDefault: values.isDefault,
      });
      setItems([...response.data.rules, ...items]);

    } catch (error) {
      setError(error);
    }
  }

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Rule',
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (isDefault, record) => {
        if (isDefault) {
          return <Tag color="green">
            {'DEFAULT'}
          </Tag>
        } else if (record.type) {
          return <>
          <Tag key={record.type}>
            {record.type.toUpperCase()}
          </Tag>
          <Tag key={record.value} color="geekblue">
            {record.value}
          </Tag>
        </>
        }
        return <Tag>
          {'UNKNOWN'}
        </Tag>
      },
    },
    {
      title: 'Redirect to',
      dataIndex: 'hostname',
      key: 'hostname',
      render: (type, record) => {
        return `${record.protocol}://${record.hostname}:${record.port}`
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Button onClick={()=> {handleEditButton(record)}}>Edit</Button>
          <Button danger onClick={()=> {deleteRule(record.id)}}>Delete</Button>
        </Space>
      ),
    },
  ];
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(RULES_API)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.rules);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
      <Space style={{
            marginBottom: 16,
          }}>
      <Button
          icon={<PlusOutlined />}
          onClick={handleAddButton}
          type="primary"
        >
          Add a Rule
        </Button>
        <Input placeholder="filter" prefix={<SearchOutlined />} onChange={filterRules} />
      </Space>


      {error && <Alert type="error" showIcon message={error.message} />}

      <Table columns={columns} dataSource={filteredRules === null ? items : filteredRules} />
      <AddOrEditRuleModal 
        visible={visibleModal}
        currentRule={currentRule}
        onConfirm={addOrEditRule}
        onCancel={() => { setVisibleModal(false);}}
      />
      </>
    );
  }
}
