import logo from './owl.svg';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import { RulesList } from './RulesList';
import { FAQ } from './FAQ';
import './App.css';
import { useState } from 'react';

const { Header, Content, Footer } = Layout;

function App() {
  const [page, setPage] = useState('home');
  return (
    <Layout>
    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <Menu theme="dark" mode="horizontal" >
      <img src={logo} alt="React Logo" style={{width: '40px'}} />
        <Menu.Item key="1" onClick={()=> {setPage('home')}}>Hedwig</Menu.Item>
        <Menu.Item key="2" onClick={()=> {setPage('faq')}}>FAQ</Menu.Item>
      </Menu>
    </Header>
    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        { page === 'home' && <RulesList />}
        { page === 'faq' && <FAQ />}

      </div>
    </Content>
    <Footer style={{ textAlign: 'center' }}>Hedwig is built by the banking team.</Footer>
    </Layout>
  );
}

export default App;
