import { Input, Modal, Form, Select, Divider, InputNumber, Radio, Checkbox } from 'antd';
import { useEffect, useState } from 'react';

export const AddOrEditRuleModal = ({
  visible,
  onConfirm,
  onCancel,
  currentRule,
}) => {
  const [form] = Form.useForm();
  const [isDefault, setIsDefault] = useState(false);

  const onCheckboxChange = (e) => {
    form.setFieldsValue({
      type: null,
      value: null,
    });
    setIsDefault(e.target.checked);
  };

  if (currentRule) {
    form.setFieldsValue({
      serviceName: currentRule.serviceName,
      type: currentRule.type, 
      value: currentRule.value,
      hostname: currentRule.hostname,
      port: currentRule.port,
      protocol: currentRule.protocol,
    });
  }
  useEffect(()=> {
    setIsDefault(currentRule && currentRule.isDefault)
  }, [currentRule]);

  const required = [
    {
      required: true,
    },
  ];
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18, offset: 6 },
  };

  return (
    <Modal
      title={currentRule?.id ? "Edit a rule" : "Add a rule"}
      afterClose={() => {
          form.resetFields();
          setIsDefault(false);
        }
      }
      visible={visible}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            form.resetFields();
            try {
              onConfirm({
                ...values,
                isDefault
              }, currentRule && currentRule.id);
            } catch (error) {}
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      onCancel={onCancel}
      okText={currentRule?.id ? "Edit" : "Add"}
      okType="primary">
      <Form
        form={form}
        layout="horizontal"
        {...layout}
        name="form_in_modal"
        initialValues={{ 
          protocol: 'https',
          port: 80,
          isDefault: false,
          }}>
        <Form.Item name="serviceName" label="Service Name" rules={required}>
          <Input placeholder="my-service-name"/>
        </Form.Item>
        <Form.Item name="isDefault" {...formTailLayout}>
          <Checkbox checked={isDefault} onChange={onCheckboxChange}>
            make default rule
          </Checkbox>
        </Form.Item>
        {!isDefault &&
          <Input.Group>
            <Form.Item name="type" label="Type" >
              <Select>
                <Select.Option value="HEADER">HEADER</Select.Option>
                <Select.Option value="QUERY_PARAM">QUERY_PARAM</Select.Option>
                <Select.Option value="COOKIES">COOKIES</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="value" label="Value" >
              <Input placeholder="your unique identifier"/>
            </Form.Item>
          </Input.Group>
        }
        <Divider plain>Redirect to</Divider>
        <Input.Group>
          <Form.Item name="protocol" label="Protocol" rules={required}>
            <Select defaultValue="https">
              <Select.Option value="http">http</Select.Option>
              <Select.Option value="https">https</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="hostname" label="Hostname" rules={required}>
            <Input placeholder="localhost" />
          </Form.Item>
          <Form.Item name="port" label="Port" rules={required} defaultValue="80">
            <InputNumber defaultValue={80}/>
          </Form.Item>
 
        </Input.Group>
      </Form>
    </Modal>
  );
};
